import { NeonButton } from 'components/zola-ui/ButtonV3';
import { DESKTOP_MIN, TABLET_MIN } from '@zola-helpers/client/dist/es/util/responsive';

import DesktopHero from 'public/static-baby/images/homepage/hero_desktop.jpg';
import TabletHero from 'public/static-baby/images/homepage/hero_tablet.jpg';
import MobileHero from 'public/static-baby/images/homepage/hero_mobile.jpg';

import Badge from 'public/static-baby/images/homepage/badge.svg';

// Styles
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import {
  HeroSection,
  MediaContainer,
  HeroImage,
  ContentContainer,
  HeroPicture,
  MobileWrapper,
  Title,
  ValueProps,
  ValueProp,
  ValuePropItem,
  ArrivalBadge,
  ValueContainer,
  CtaWrapper,
  HideForDesktop,
  HideForMobile,
} from './styles';

const ValuePropsComponent = (): JSX.Element => (
  <ValueContainer>
    <ValueProps>
      <ValuePropItem>
        <ValueProp presentation="h4">Curated gifts from the most trusted brands</ValueProp>
      </ValuePropItem>
      <ValuePropItem>
        <ValueProp presentation="h4">Add gifts from any store</ValueProp>
      </ValuePropItem>
      <ValuePropItem>
        <ValueProp presentation="h4">Zero-fee cash funds</ValueProp>
      </ValuePropItem>
      <ValuePropItem>
        <ValueProp presentation="h4">Choose when your gifts ship</ValueProp>
      </ValuePropItem>
    </ValueProps>
    <a href="/expected-arrival-discount">
      <ArrivalBadge src={Badge} alt="Expected arrival discount 15%" />
    </a>
  </ValueContainer>
);

const Hero = (): JSX.Element => {
  const showAppDowloadModal = () => {
    const detail = {
      type: 'MODAL_TYPE_BABY_APP_DOWNLOAD',
      modalProps: {
        source: 'LANDING_PAGE',
      },
    };
    window.dispatchEvent(new CustomEvent('OPEN_MODAL_EVENT', { detail }));
  };

  return (
    <>
      <HeroSection>
        <MediaContainer>
          <HeroPicture>
            <source srcSet={DesktopHero} media={`(min-width: ${DESKTOP_MIN}px)`} />
            <source srcSet={TabletHero} media={`(min-width: ${TABLET_MIN}px)`} />
            <HeroImage src={MobileHero} alt="" />
          </HeroPicture>
        </MediaContainer>
        <ContentContainer>
          <Title presentation="h2">The baby registry that has it all</Title>
          <ValuePropsComponent />
          <CtaWrapper>
            <HideForMobile>
              <NeonButton component="button" onClick={showAppDowloadModal} size="large">
                Download the app
              </NeonButton>
            </HideForMobile>
            <HideForDesktop>
              <NeonButton
                component="a"
                href="https://apps.apple.com/app/apple-store/id6446598574?pt=31209800&ct=zola_web_lp&mt=8"
                target="_blank"
                rel="noopener nofollow"
                size="large"
              >
                Download the app
              </NeonButton>
            </HideForDesktop>
            <ButtonV3 component="a" href="/search/baby-registry" size="large">
              Find a registry
            </ButtonV3>
          </CtaWrapper>
        </ContentContainer>
      </HeroSection>
      <MobileWrapper>
        <ValuePropsComponent />
      </MobileWrapper>
    </>
  );
};

export default Hero;
