import styled from '@emotion/styled';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import COLORS from '@zola/zola-ui/src/styles/emotion/colorsBaby';

export const NeonButton = styled(ButtonV3)`
  background-color: ${COLORS.NEON_LIME_100};
  border-color: ${COLORS.NEON_LIME_100};
  color: ${COLORS.PD_ULTRAMARINE_100};
  :hover {
    background-color: ${COLORS.NEON_LIME_105};
    border-color: ${COLORS.NEON_LIME_105};
    color: ${COLORS.PD_ULTRAMARINE_100};
  }
  :active,
  :focus {
    background-color: ${COLORS.NEON_LIME_105};
    border-color: ${COLORS.NEON_LIME_105};
    color: ${COLORS.PD_ULTRAMARINE_100};
  }
  :disabled {
    background-color: ${COLORS.BLACK_030};
    border-color: ${COLORS.BLACK_030};
    color: ${COLORS.WHITE_100};
  }
`;
