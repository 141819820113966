import styled from '@emotion/styled';
import { ModuleTile } from '@zola/zola-ui/src/components/Grid';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';
import ZINDEX from '@zola/zola-ui/src/styles/emotion/zIndex';
import COLORS from '@zola/zola-ui/src/styles/emotion/colorsBaby';
import H from '@zola/zola-ui/src/typography/Headings';

export const HeroSection = styled.section`
  display: flex;
  aspect-ratio: 1920 / 745;
  justify-content: center;
  z-index: ${ZINDEX.bottom};
  position: relative;
  ${MEDIA_QUERY.MOBILE} {
    aspect-ratio: 375 / 360;
  }
`;

export const MediaContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: ${ZINDEX.bottom};

  ${MEDIA_QUERY.MOBILE} {
    height: inherit;
  }
`;

export const HeroPicture = styled.picture``;

export const HeroImage = styled.img`
  width: 100%;
  object-fit: scale-down;

  ${MEDIA_QUERY.TABLET} {
    height: 100%;
    width: 100%;
    object-fit: scale-down;
  }

  ${MEDIA_QUERY.MOBILE} {
    height: 100%;
    width: 100%;
    object-fit: scale-down;
  }
`;

export const MobileWrapper = styled(ModuleTile)`
  ${MEDIA_QUERY.DESKTOP} {
    display: none;
  }
`;

export const Title = styled(H.TitleMedium1)`
  color: ${COLORS.PD_ULTRAMARINE_100};
  text-align: center;
`;

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0px;
  align-items: flex-start;
  ${MEDIA_QUERY.TABLET} {
    column-gap: ${SPACING.S32};
    align-items: center;
    justify-content: center;
  }
  ${MEDIA_QUERY.DESKTOP} {
    column-gap: 64px;
    align-items: center;
  }
`;

export const ValueProps = styled.ul`
  list-style: disc;
  margin-left: 24px;
  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    color: ${COLORS.BLACK_100};
  }
`;

export const ValuePropItem = styled.li`
  ${MEDIA_QUERY.MOBILE} {
    font-size: 16px;
    h4 {
      font-size: 16px;
      line-height: 24px;
    }
  }
  ${MEDIA_QUERY.TABLET} {
    font-size: 16px;
    h4 {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const ValueProp = styled(H.Title4)`
  ${MEDIA_QUERY.DESKTOP} {
    color: ${COLORS.PD_ULTRAMARINE_100};
  }
`;

export const ArrivalBadge = styled.img`
  width: 80px;
  ${MEDIA_QUERY.DESKTOP} {
    width: 130px;
  }
`;

export const CtaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  ${MEDIA_QUERY.MOBILE} {
    position: absolute;
    bottom: 32px;
  }
`;

export const ContentContainer = styled.div`
  align-items: flex-start;
  color: ${COLORS.PD_ULTRAMARINE_100};
  display: flex;
  flex-flow: column;
  justify-content: center;
  z-index: ${ZINDEX.middle};
  width: 100%;
  padding: 0 100px;
  row-gap: 24px;

  ${MEDIA_QUERY.MOBILE} {
    padding: 16px;
    width: 100%;
    align-items: flex-start;
  }

  ${MEDIA_QUERY.TABLET} {
    align-items: center;
    padding: 16px;
  }

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    ${ValueContainer} {
      display: none;
    }
  }
`;

export const HideForDesktop = styled.div`
  ${MEDIA_QUERY.DESKTOP} {
    display: none;
  }
`;

export const HideForMobile = styled.div`
  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    display: none;
  }
`;
